import { httpRequestO, httpRequestX } from "../../../../utils/httpsRequest";


// var prompt = 
// "You are a helpful assistant. You answer by generating code in the specified programming language. Generate codes with correct indentations.\n" +
// `Respond in this format:
// \`\`\`language
// code
// \`\`\``;
// const sys = "You are a helpful assistant. You answer by generating code with the specified programming language. Generate codes with correct indentations. Generate code blocks strictly in this format:\n```language\n// Your code here\n```";
const sys = "You are a helpful assistant. You follow the constraints:"+
`
1. All code should be generated within the "\`\`\`Code\`\`\`" format to maintain proper structure and readability.
2. Avoid using code that requires user inputs. Instead, create and use sample values to demonstrate functionality.
3. Ensure the code sticks to text outputs, avoiding graphical interfaces or visualizations unless explicitly requested.
4. The code should follow proper syntax and structure, adhering to Python's best practices and conventions.
5. Use accurate and descriptive variable names, objects, and dictionaries that clearly reflect their purpose.
6. Maintain correct and consistent indentation throughout the code to ensure clarity and prevent syntax errors.
7. Limit responses to the code only, without additional explanations or commentary unless explicitly requested.
8. Include basic error handling to manage potential runtime issues gracefully, such as handling network errors or invalid data.
9. Prioritize readability by including comments where necessary to explain complex logic or decisions within the code.
10. Ensure all outputs are formatted clearly and consistently, with labels or context to make the results understandable.
11. Use descriptive and meaningful names for variables, functions, and classes to make the code self-explanatory.
12. Write optimized code, avoiding unnecessary operations, redundant calculations, or inefficient loops.
13. Ensure the code is compatible with the latest version of Python or the specified version if mentioned in the prompt.
14. Properly manage resources, ensuring that any opened files, network connections, or external resources are closed or cleaned up appropriately.
15. Do not use the if \`__name__ == "__main__":\` construct in the code.
16. Ensure that the code includes functionality to display output, so results can be observed without additional code modifications.
17. Prohibit using files for source or output. All data handling should be done using in-memory structures or other non-file methods.
18. Avoid using recursive functions in the code.
19. Do not use global variables, modules, and other functions inside functions; instead, pass them as arguments.
20. Lessen the use of imports in the code if possible.
21. Lessen the use of classes in the code if possible.
`;

const parameters = {
    mode: "sampling",
    method: "sampling",
    temperature: 0.7,
    top_p: 1,
    top_K: 50,
    max_tokens: 16384,
    max_new_tokens: 2000,
    stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
    repetition_penalty: 1.2
}



export const Prompt = async (query, convo) => {
    console.log(convo)
    const formattedConversation = convo.reduce((acc, { role, message }, index) => {
        if (index % 2 === 0) {
          // Starting a new conversation block with a user message
          acc.push(`<s>\n[INST]\n${message}\n[/INST]`);
        } else {
          // Adding the assistant's response to the conversation block
          acc[acc.length - 1] += `\n${message}\n</s>`;
        }
        return acc;
      }, []).join("\n\n");
    
      console.log(formattedConversation);
    
// mistralai/mixtral-8x7b-instruct-v01
var prompt =
`${convo.length === 0 ?
""
:
`${formattedConversation}
`}<s>
[INST]
${sys}
${query}
[/INST]
`;

// codellama/codellama-34b-instruct-hf
// var prompt =
// `<s>
// <<SYS>>
// ${sys}
// <</SYS>>
// [INST]
// ${query}
// [/INST]`;

// ibm/granite-3b-code-instruct
// var prompt =
// `
// <|system|>
// ${sys}

// <|user|>
// ${query}

// <|assistant|>
// `;

console.log(prompt)
    try{
        const result = await httpRequestO.post('/projecto/api/v1/model/foundationModel/experiment?mode=freeMode', {
            // modelId: "codellama/codellama-34b-instruct-hf",
            modelId: "mistralai/mixtral-8x7b-instruct-v01",
            parameters: parameters,
            prompt: prompt,
            query: ""
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(result)
        // if (result.ok)
        return result.data.prediction
    }
    catch (error)
    {
        console.log(error);
    }
}