import React, { useContext, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "../../../../components/ui/card";
import { Button } from "../../../../components/ui/button";
import { RotateCcw, CheckCheck, SendHorizontal, Info, Copy, Upload, FileText, Trash2, View } from 'lucide-react';
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Checkbox } from "../../../../components/ui/checkbox";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../../components/ui/tooltip";

import { PartContext } from "../../context/PartContext";
import {LoaderSpinner} from "../../../../components/LoaderSpinner";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
  } from "../../../../components/ui/dropdown-menu";

  import {DocumentList} from "./RAGDocumentList";
export function RAGLive() {

    const {
        user,
        queryRAG, addNewNotifcation,
        files, setFiles,
        processDocuments,
        fileProcessLoading,
        messagesLive, setMessagesLive,
        selectedDocuments, setSelectedDocuments,
        queryWithID,
        prevSelectedDocumentsRef,
        setUploadedDocs, getDocList
    } = useContext(PartContext);


    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const endOfMessagesRef = useRef(null);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const fileInputRef = useRef(null);
    const [disabled, setDisabled] = useState(false);
    const containerRef = useRef(null);

    const [selectedMethod, setSelectedMethod] = useState('watson_discovery')
    const METHODS = [
        {_id: 1, name: "Method A", value: "watson_discovery"},
        {_id: 2, name: "Method B", value: "text_embedding"}
    ]

    const onSelectMethod = (value) => {
        // console.log(value)
        setSelectedMethod(METHODS.find(item => value._id === item._id).value)
    }

    const getFileExtension = (url) => {
        return url.split('.').pop().split(/\#|\?/)[0];
    };

    const ConstructEmbeddingResult = (documents) => {
        console.log(documents)
        let str = "";
        documents.forEach((doc) => {
            let ext = getFileExtension(doc.metadata.name);
            let ppt = ext === "ppt" || ext === "pptx" || ext === "PPT" || ext === "PPTX";
            str += "[Document]\n";
            str += ppt ? `[Page ${doc.metadata.slide_number}]\n` : "";
            str += `${doc.metadata.name}\n`;
            str += `${doc.page_content}\n`;
            str += ppt ? `[Page ${doc.metadata.slide_number}]\n` : "";
            str += "[End]\n\n";
        })
        return str;
    }
      

    const handleSend = async () => {
        if (input.trim() === '') return;  // Prevent sending empty messages
        

        if (selectedDocuments.length === 0){
            addNewNotifcation("Please select a document to query.", "danger");
            return;
        }

        const newMessage = { role: 'user', msg: input, _id: Date.now() };
        setMessagesLive(prevMessages => [...prevMessages, newMessage]);
        setInput('');

        
        try {
            setLoading(true);
            let rag = "";
            const totalK = 15;
            const numberOfDocs = selectedDocuments.length;
            const baseK = Math.floor(totalK / numberOfDocs);
            const remainderK = totalK % numberOfDocs;
            for (let i = 0; i < numberOfDocs; i++) {
                const k = baseK + (i < remainderK ? 1 : 0);
                const response = await queryWithID(selectedDocuments[i]._id, input, user, k);
                // console.log(response);
                rag += ConstructEmbeddingResult(response.documents);
            }
            console.log(rag);
            const ragMessage = rag ? { role: 'rag', msg: rag.trim(), _id: Date.now() } : { role: 'system', msg: "I'm sorry that information is not found in the document.", _id: Date.now() };
            setMessagesLive(prevMessages => [...prevMessages, ragMessage]);
 
        } catch (error) {
            console.error(error);
            setMessagesLive(prevMessages => [
                ...prevMessages,
                { role: 'rag', msg: "An error occurred while fetching the information.", _id: Date.now() }
            ]);
        } finally {
            setLoading(false);
        }
            

    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleSend();
        }
    };

    const handleResetRAG = (e) => {
        prevSelectedDocumentsRef.current = [];
        setSelectedDocuments([])
        setMessagesLive([{role: "system", msg: "Hi. Please select a document to start querying.", _id: Date.now()}]);
        setSelectedMessages([]);
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear the file input
        }
    };

    useEffect(() => {
        if (endOfMessagesRef.current) {
            // endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [messagesLive]);

    const handleCheckboxChange = (e, msg) => {
        setSelectedMessages(prevSelectedMessages => {
            if (prevSelectedMessages.includes(msg)) {
                return prevSelectedMessages.filter(m => m !== msg);
            } else {
                return [...prevSelectedMessages, msg];
            }
        });
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
    };

    useEffect(() => {
        setFiles([])
    }, [])

    const viewFile = () => {
        // console.log(selectedDocuments);
        if (selectedDocuments.length !== 0){
            window.open(selectedDocuments[0].file_url, '_blank', 'noopener,noreferrer');
        }
        else{
            addNewNotifcation("Please select a document first.", "warning");
        }
        // alert("View");
    };

    return (
        <Card className="w-full flex flex-col justify-between h-fit min-h-[436px] max-h-[436px]">
            <CardHeader className="max-h-[76px] border-b-[1px] border-gray-200 px-6 py-3.5 grid grid-cols-2 gap-4">
                {/* <div className="flex flex-row flex-nowrap items-center gap-2">
                    <Label htmlFor="file" className="whitespace-nowrap text-md">RAG</Label>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button variant="ghost" size="w-5 h-5">
                                    <Info className="w-5 h-5" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent className="w-[300px]">
                                <p>Select from uploaded documents using the file icon at the bottom left side of the chat box. Upload files (PDF, DOCX, XLSX, PPT/PPTX) at the top of the chat box.</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                    
                </div>
                <div className="mt-0">
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button size="icon" variant="outline" onClick={viewFile}>
                                    <View className="h-5 w-5" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>View File</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div> */}
                <div className="grid grid-cols-1 gap-1.5">
                    <CardTitle className="text-sm flex items-center gap-2">RAG

                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button variant="ghost" size="w-5 h-5">
                                        <Info className="w-5 h-5" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent className="w-[300px]">
                                    <p>Select and upload documents using the file icon at the bottom left side of the chat box. Supported file types are: (PDF, DOCX, XLSX, PPT/PPTX, TXT).</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </CardTitle>
                    <CardDescription className="text-xs">Retrieve information from your documents.</CardDescription>
                </div>
                <div className="flex flex-row items-center justify-end gap-2">
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button size="icon" variant="outline" onClick={viewFile}>
                                    <View className="h-5 w-5" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>View File</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
             
            </CardHeader>

            <CardContent className="flex flex-col justify-end gap-4 p-0 flex-grow">
            {/* <ScrollArea className="h-72 w-full rounded-md"> */}
                    <div className="max-h-[296px] px-6 overflow-auto" ref={containerRef}>
                        {
                            messagesLive.map((chat) => (
                                <div key={chat._id} className={`flex mb-4 mt-4 gap-2 items-start ${chat.role === "user" ? 'justify-end' : ''}`}>
                                    {chat.role === "rag" && <Checkbox className="mt-2" onCheckedChange={(e) => handleCheckboxChange(e, chat.msg)} checked={selectedMessages.includes(chat.msg)}/>}
                                    <div className={`${chat.role === "user" ? "bg-black text-white" : chat.role === "ref" ? "bg-none text-gray-400 italic" : "bg-gray-100 text-gray-900"} rounded-lg p-3 max-w-[75%]`}>
                                        <p className="text-sm break-words whitespace-pre-line">{chat.msg}</p>
                                    </div>
                                </div>
                            ))
                        }
                        {loading && 
                            <div className="rag-typing mb-4">
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                            </div>
                        }
                        
                        <div ref={endOfMessagesRef} />
                    </div>
                {/* </ScrollArea> */}
            </CardContent>
            
            <CardFooter className="h-16 border-t-[1px] border-gray-200 px-6 py-4 flex flex-row flex-nowrap gap-2">
                
                <DocumentList messagesLive={messagesLive} setMessagesLive={setMessagesLive} handleFileChange={handleFileChange} fileInputRef={fileInputRef}/>
                <Input
                    value={input}
                    onChange={(e)=>{setInput(e.target.value)}}
                    onKeyDown={handleKeyDown}
                    type="text"
                    placeholder="Send a query..."
                    className="col-span-1 h-10"
                />
                <Button variant="ghost" size="icon" className="px-2" onClick={handleSend}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
                <CopyLive selectedMessages={selectedMessages}/>
                <Button variant="ghost" size="icon" className="px-2" onClick={handleResetRAG}>
                    <RotateCcw className="w-5 h-5" />
                </Button>
            </CardFooter>
        </Card>
    )
}

export function CopyLive({selectedMessages}) {
    const {
        promptLive, setPromptLive,
        addNewNotifcation,
    } = useContext(PartContext);

    const dataSelected = () => {
        if (selectedMessages.length > 0)
            return true;
        else
            return false;
    };


    const handleCopyToContext = (e) => {
        if (!dataSelected()){
            addNewNotifcation('Please select a queried information first.', 'danger');
            return;
        }

        const newMessages = selectedMessages.filter(msg => !promptLive.context.includes(msg));
        if (newMessages.length > 0) {
            setPromptLive({...promptLive, context: (promptLive.context ? promptLive.context + '\n\n' : "") + newMessages.join('\n\n')});
        }
    }
    
    const handleCopyToInput = (e) => {
        if (!dataSelected()){
            addNewNotifcation('Please select a queried information first.', 'danger');
            return;
        }

        const newMessages = selectedMessages.filter(msg => !promptLive.input_data.includes(msg));
        if (newMessages.length > 0) {
            setPromptLive({...promptLive, input_data: (promptLive.input_data ? promptLive.input_data + '\n\n' : "") + newMessages.join('\n\n')});
        }
    }

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="px-2">
                <Copy className="w-5 h-5" />
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end">
          <DropdownMenuLabel>Copy Selected Data To</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={handleCopyToContext}>
              <span>Context</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleCopyToInput}>
              <span>Input Data</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    )
}


