import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { generatePrompt, consolidate, queryWithID, getDocList, processFile, deleteDocument } from './functions/generation';
import {Context} from "../../../context/GlobalState";
import {SubOrgContext} from "context/SubOrganizationContext";

export const PartContext = createContext({});

export const PartProvider = ({ children }) => {

  const {addNewNotifcation, user} = useContext(Context);
  const {selectedSubOrganization} = useContext(SubOrgContext);

  // Demo
  const [promptDemo, setPromptDemo] = useState({ instruction: "", context: "", target_format: "", input_data: "", sample_input: "", sample_output: "" });
  const [promptOutputDemo, setPromptOutputDemo] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [selectedModelDemo, setSelectedModelDemo] = useState("");
  const [parametersDemo, setParametersDemo] = useState({mode: "greedy", temperature: 0.7, max_tokens: 0, max_new_tokens: 200, top_p: 1, top_k: 50, stop_sequences: ["<|endoftext|>", "<|endofcode|>"], repetition_penalty: 1.2});
  const [generateLoadingDemo, setGenerateLoadingDemo] = useState(false);
  // RAG
  const [selectedDocumentDemo, setSelectedDocumentDemo] = useState("");
  const [messagesDemo, setMessagesDemo] = useState([{role: "system", msg: "Hi. Please select a document to start querying.", _id: Date.now()}]);
  const [selectedMethodDemo, setSelectedMethodDemo] = useState('text_embedding')

  // Live
  const [promptLive, setPromptLive] = useState({ instruction: "", context: "", target_format: "", input_data: "", sample_input: "", sample_output: "" });
  const [promptOutputLive, setPromptOutputLive] = useState("");
  const [selectedModelLive, setSelectedModelLive] = useState("");
  const [parametersLive, setParametersLive] = useState({mode: "greedy", temperature: 0.7, max_tokens: 0, max_new_tokens: 200, top_p: 1, top_k: 50, stop_sequences: ["<|endoftext|>", "<|endofcode|>"], repetition_penalty: 1.2});
  const [generateLoadingLive, setGenerateLoadingLive] = useState(false);
  // RAG
  
  const [files, setFiles] = useState([]);
  const [fileProcessLoading, setFileProcessLoading] = useState(false);
  const [previouslyProcessed, setPreviouslyProcessed] = useState("");
  const [messagesLive, setMessagesLive] = useState([{role: "system", msg: "Hi. Please select a document to start querying.", _id: Date.now()}]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const prevSelectedDocumentsRef = useRef([]);

  // GENERATE DEMO
  const generateDemo = () => {
    
    if (!selectedModelDemo) {
        addNewNotifcation('No selected foundation model. Please select a Foundation Model before proceeding', 'danger');
        return;
    }

    if (!promptDemo.instruction) {
      addNewNotifcation('Please provide an instruction.', 'danger');
      return;
    }

    setGenerateLoadingDemo(true);
    generatePrompt(promptDemo, parametersDemo, selectedModelDemo).then((data) => {
      console.log("OUTPUT", data.data);
      setPromptOutputDemo(data.data.prediction.trim());
      addNewNotifcation('Generation successful. Please see the Output for results.', 'success');
    }).catch((error) => {
      console.log(error);
      addNewNotifcation('Generation unsuccessful. Please try again.', 'error');
    }).finally(() => {
      setGenerateLoadingDemo(false);
    });
  }

  // GENERATE LIVE
  const generateLive = () => {
    
    if (!selectedModelLive) {
        addNewNotifcation('No selected foundation model. Please select a Foundation Model before proceeding', 'danger');
        return;
    }

    if (!promptLive.instruction) {
      addNewNotifcation('Please provide an instruction.', 'danger');
      return;
    }

    setGenerateLoadingLive(true);
    generatePrompt(promptLive, parametersLive, selectedModelLive).then((data) => {
      console.log("OUTPUT", data.data);
      setPromptOutputLive(data.data.prediction.trim());
      addNewNotifcation('Generation successful. Please see the Output for results.', 'success');
    }).catch((error) => {
      console.log(error);
      addNewNotifcation('Generation unsuccessful. Please try again.', 'error');
    }).finally(() => {
      setGenerateLoadingLive(false);
    });
  }

  

  const getConsolidatedPrompt = (live = false) => {
    if (live){
        return consolidate(promptLive);
    }
    return consolidate(promptDemo);
  }

  const queryRAG = async (ragQuery) => {
    let selectedDocument = {};
    selectedDocument = selectedDocumentDemo._id;
    const result = await queryWithID(selectedDocument, ragQuery, user);
    return result;
    
  }

  async function loadDocuments() {
    try{
        const docList = await getDocList(user);
        console.log(docList);
        if (docList) setUploadedDocs(docList)
    }
    catch (error){
        console.log(error)
        setUploadedDocs([])
    }
  }

  const processDocuments = async () => {
    if (files.length === 0) {
        addNewNotifcation("Please choose at least 1 file.", "danger");
        return;
    }

    let processed = 0;
    let alreadyProcessed = 0;

    try {
        setFileProcessLoading(true);

        // Fetch document list
        const docList = await getDocList(user, selectedSubOrganization._id);

        for (const file of files) {
            console.log("Processing: ", file.name);
            const filename = file.name;
            const match = docList.find(doc => doc.name === filename);

            if (match) {
                console.log("Uploaded file is already processed.");
                alreadyProcessed = alreadyProcessed + 1;
            } else {
                // Process file asynchronously
                const result = await processFile(file,files, user, selectedSubOrganization._id);
                // console.log("AFTER", result)
                if (typeof result === 'string' && result !== null && result !== undefined && !(result instanceof Error)) {
                    console.log("PROCESS RESULT: ", result);
                    processed = processed + 1;
                } else {
                    console.log("Something went wrong while processing the file.");
                }
            }
        }

        console.log("Processed:", processed);
        console.log("Already Processed:", alreadyProcessed);

        if (processed === 0 && alreadyProcessed === 0)
          addNewNotifcation("File not processed. Something went wrong.", "danger");
        else
        addNewNotifcation(
            `${processed > 0 ? processed + ' file(s) successfully processed.' : ''} ${alreadyProcessed > 0 ? alreadyProcessed + ' file(s) already processed.' : ''}`,
            'success'
        );
    } catch (error) {
        console.error("Error processing documents:", error);
        addNewNotifcation(`Something went wrong. ${files.length - processed - alreadyProcessed} file(s) not processed.`, 'danger');
    } finally {
        setFileProcessLoading(false);
        loadDocuments();
    }
  };
  
  const deleteUploadedDocs = async (id) => {
    await deleteDocument(id, user, selectedSubOrganization._id,  addNewNotifcation, loadDocuments);
  }
  

  const value={
    // Demo
    promptDemo, setPromptDemo,
    promptOutputDemo, setPromptOutputDemo,
    selectedTask, setSelectedTask,
    selectedModelDemo, setSelectedModelDemo,
    parametersDemo, setParametersDemo,
    generateDemo, generateLoadingDemo,
    // RAG
    selectedDocumentDemo, setSelectedDocumentDemo,
    messagesDemo, setMessagesDemo,
    selectedMethodDemo, setSelectedMethodDemo,

    // Live
    promptLive, setPromptLive,
    promptOutputLive, setPromptOutputLive,
    selectedModelLive, setSelectedModelLive,
    parametersLive, setParametersLive,
    generateLive, generateLoadingLive,
    // RAG
    files, setFiles,
    fileProcessLoading, setFileProcessLoading,
    previouslyProcessed, setPreviouslyProcessed,
    messagesLive, setMessagesLive,
    selectedDocuments, setSelectedDocuments,
    prevSelectedDocumentsRef,
    uploadedDocs, setUploadedDocs,


    // functions
    getConsolidatedPrompt,
    addNewNotifcation,
    queryRAG,
    queryWithID,
    processDocuments,
    getDocList,
    loadDocuments,
    deleteUploadedDocs,

    user,
    selectedSubOrganization
  }

  return (
    <PartContext.Provider value={value}>
      {children}
    </PartContext.Provider>
  );
};